import React from "react";

import IMAGE_1 from "../../images/about-1.svg";
import IMAGE_2 from "../../images/about-2.svg";
import IMAGE_3 from "../../images/about-3.svg";
import IMAGE_4 from "../../images/about-4.svg";

import { Container, ContentContainer } from "./styles";
import { Item } from "./Item";
import Layout from "../Layout";

export const HowItWorks = (props) => (
  <Container {...props}>
    <Layout>
      <ContentContainer>
        <Item
          title="Mapeamos o problema"
          description="Através do mapeamento de problemas iremos saber qual será a melhor ação a ser tomada."
          bottomImage={IMAGE_1}
        />
        <Item
          title="Estudamos as personas"
          description="Através do estudo das personas, identificamos os possíveis usuários do sistema ou aplicativo que iremos construi, assim saberemos como e que tipo de interface irá se comunicar com ele."
          headerImage={IMAGE_2}
          align="right"
        />
        <Item
          title="Protótipo"
          description="Depois de mapear e ter o resultado dos estudo, iremos fazer o protótipo de baixa fidelidade para apresentação ao cliente, assim reduzimos o risco de descoberta de erro ao fim do projeto."
          bottomImage={IMAGE_3}
        />
        <Item
          title="Desenvolvimento e Entrega"
          description="Após a aprovação do protótipo pelo cliente, então começamos o desenvolvimento, sempre separado em pequenas entregas para validação junto ao cliente, geralmente em sprints de 1 ou 2 semanas para realização das pequenas entregas."
          headerImage={IMAGE_4}
          align="right"
        />
      </ContentContainer>
    </Layout>
  </Container>
);

import '../assets/normalize.css';
import '../assets/fonts.css';
import * as React from "react"
import { Helmet } from "react-helmet"

import GlobalStyles from '../global.styles';
import { Header, Hero, HowItWorks, About, Contact } from '../components'

const IndexPage = () => {
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Rigel Softwares - Soluções em Softwares</title>
        <link rel="canonical" href="https://rigelsoftwares.com/" />
      </Helmet>
      <Header />
      <Hero id="home" />
      <About id="about" />
      <HowItWorks id="how-it-works" />
      <Contact id="contact" />
      <GlobalStyles />
    </React.Fragment>
  )
}

export default IndexPage

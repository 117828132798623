import styled from 'styled-components';

export const Container = styled.header`
  width: 100%;
  background-color: #090918;
  height: 80px;
  display: flex;
  align-items: center;
  padding: 12px 0px;
  position: fixed;
`;

export const Layout = styled.section`
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MenuContainer = styled.ul`
  list-style-type: none;
  display: flex;
  align-items: center;
  

  li + li {
    margin-left: 36px;
  }

  li > a {
    font-size: 20px;
    /* font-weight: bold; */
    line-height: 26px;
    background-color: transparent;
    border: none;
    color: #FFF;
    font-family: 'Raleway', sans-serif;
    cursor: pointer;
  }
`;

import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0px;
    padding: 0px;
  }

  html, body, #___gatsby {
    min-height: 100%;
    height: 100%;
  }
`;

import React from "react";

import { Container, ContentContainer } from "./styles";
import Layout from "../Layout";
import { navigateToSection } from '../../helpers/navigation';

export const Hero = (props) => (
  <Container {...props}>
    <Layout>
      <ContentContainer>
        <h1>Construindo softwares da maneira certa!</h1>
        <h2>
          Da ideia ao produto, nós ajudamos a sua empresa a criar aplicativos
          inovadores que deixarão seus clientes satisfeitos e seus concorrentes
          comendo poeira.
        </h2>
        <button type="button" onClick={() => navigateToSection('contact')}>Entre em contato</button>
      </ContentContainer>
    </Layout>
  </Container>
);

import styled from 'styled-components';
import BACKGROUND from '../../images/background-innovation.png';

export const Container = styled.section`
  width: 100%;
  background-color: #ffffff;
  height: 100vh;

  background-image: url('${BACKGROUND}');
  background-size: 100% cover;
  background-position: center right;
  background-repeat: no-repeat;

  & > section {
    height: 100%;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  width: 75%;

  h3, p {
    font-family: Raleway, sans-serif;
  }

  h3 {
    font-size: 40px;
    line-height: 47px;
    font-weight: 600;
    margin-bottom: 24px;
  }

  p {
    font-size: 18px;
  }

  p + p {
    margin-top: 16px;
  }

  img {
    width: 100%;
    max-width: 100%;
    margin-top: 48px;
  }
`;

import React from 'react';

import LOGO_IMAGE from '../../images/logo.svg';

import Layout from '../Layout';
import { Container, ContentContainer } from './styles';

export const Contact = (props) => (
  <Container {...props}>
    <Layout>
      <ContentContainer>
        <h3>Entre em contato</h3>
        <div className="row">
          <img src={LOGO_IMAGE} alt="Rigel Softwares" />
          <section className="address">
            <span>Av. Cardoso de Sá, 860 - Sala 05 - Petrolina/PE</span>
            <span>(87) 99907-9455</span>
            <span>contato@rigelsoftwares.com</span>
          </section>
        </div>
      </ContentContainer>
    </Layout>
  </Container>
)
import styled, { css } from 'styled-components';

export const Container = styled.section`
  width: 100%;
  background-color: #101029;
  padding: 120px 0px;
`;

export const ItemContainer = styled.article`
  h3, p {
    color: #fff;
    font-family: Raleway, sans-serif;
    margin: 0px;
    padding: 0px;
  }

  h3 {
    font-size: 24px;
    margin-bottom: 12px;
  }

  p {
    font-size: 16px;
  }

  ${({ align }) => align === 'right' && css`
    text-align: right;
  `}

  img + h3 {
    margin-top: 32px;
  }

  p + img {
    margin-top: 32px;
  }
`;

export const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 100px;
`;

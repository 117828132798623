import styled from 'styled-components';

const Layout = styled.section`
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
`;

export default Layout;

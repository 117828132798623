import React from "react";

import ABOUT from '../../images/about.png';
import { Container, ContentContainer } from "./styles";
import Layout from "../Layout";

export const About = (props) => (
  <Container {...props}>
    <Layout>
      <ContentContainer>
        <h3>Sobre nós</h3>
        <p>
          Somos uma empresa focada no desenvolvimento de aplicativos,
          plataformas web e ferramentas de gerenciamento a mais de 10 anos,
          nosso foco atual é na satisfação do cliente, estamos sempre buscando a
          melhoria contínua dos nossos processo de construção de softwares para
          atender cada vez melhor nossos clientes.
        </p>

        <p>
          Garantimos o bom relacionamento entre clientes e empresas através do
          softwares, reduzindo as burocracias do dia a dia dentro das empresa,
          deixando os funcionários fazer o que fazem de melhor.
        </p>

        <img src={ABOUT} alt="Sobre a Rigel Softwares" />
      </ContentContainer>
    </Layout>
  </Container>
);

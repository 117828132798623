import React from 'react';

import { ItemContainer } from './styles';

export const Item = ({ title, description, headerImage, bottomImage, align = 'left' }) => (
  <ItemContainer {...{ align }}>
    {!!headerImage && <img src={headerImage} alt={title} />}
    <h3>{title}</h3>
    <p>{description}</p>
    {!!bottomImage && <img src={bottomImage} alt={title} />}
  </ItemContainer>
)
import React from 'react';

import LOGO_IMAGE from '../../images/logo.svg';
import { Container, MenuContainer } from './styles';
import Layout from '../Layout';
import { navigateToSection } from '../../helpers/navigation';

const MenuItem = ({ children, onClick }) => <a {...{ onClick }}>{children}</a>

export const Header = () => (
  <Container>
    <Layout>
      <img src={LOGO_IMAGE} alt="Rigel Softwares" />
      <MenuContainer>
        <li>
          <MenuItem onClick={() => navigateToSection('home')}>Home</MenuItem>
        </li>
        <li>
          <MenuItem onClick={() => navigateToSection('about')}>Sobre</MenuItem>
        </li>
        <li>
          <MenuItem onClick={() => navigateToSection('how-it-works')}>Como funciona</MenuItem>
        </li>
        <li>
          <MenuItem onClick={() => navigateToSection('contact')}>Fale conosco</MenuItem>
        </li>
      </MenuContainer>
    </Layout>
  </Container>
);
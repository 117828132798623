import styled from 'styled-components';

export const Container = styled.section`
  padding: 80px 0px;
  background-color: #090918;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  span, h3 {
    color: #fff;
    font-family: Raleway, sans-serif;
  }

  h3 {
    text-align: center;
    display: block;
    width: 100%;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 32px;
  }

  div.row {
    display: flex;
  }

  section.address {
    margin-left: 48px;
    padding-left: 48px;
    border-left: 1px solid rgba(255, 255, 255, 0.05);
    display: flex;
    flex-direction: column;

    span + span {
      margin-top: 4px;
    }
  }
`;

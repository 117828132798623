import styled from 'styled-components';
import HERO_BACKGROUND from '../../images/hero-background.svg';

export const Container = styled.section`
  width: 100%;
  background-color: #101029;
  height: 100vh;

  background-image: url('${HERO_BACKGROUND}');
  background-size: 45%;
  background-position: bottom right;
  background-repeat: no-repeat;

  & > section {
    height: 100%;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: calc(104px + 120px);

  h1, h2, button {
    font-family: Raleway, sans-serif;
    color: #fff;
    margin: 0px;
    padding: 0px;
  }

  h1 {
    font-size: 58px;
    font-weight: bold;
    width: 70%;
  }

  h2 {
    font-size: 18px;
    line-height: 21px;
    font-weight: 400;
    width: 60%;
    margin-top: 32px;
  }

  button {
    margin-top: 64px;
    align-self: flex-start;
    padding: 12px 24px;
    color: #101029;
    background-color: #fff;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-size: 18px;
  }
`;
